
	import _prop from 'vue-types';

	export default {
		name: 'content-block-wysiwyg',
		components: {},
		props: {
			text: _prop.string,
			centered: _prop.bool.def(false),
			container: _prop.bool.def(false),
		},
		data: () => ({}),
		computed: {},
		methods: {},
		mounted() {
			const wysiwyg = this.$refs.self;
			if (!wysiwyg) return;

			// Wrap all tables cause we need mobile scroll
			wysiwyg.querySelectorAll('table').forEach(table => {
				if (!table.parentNode.classList.contains('base-content-table-wrapper')) {
					const wrapper = document.createElement('div');
					wrapper.classList.add('base-content-table-wrapper');
					table.parentNode.append(wrapper);
					wrapper.append(table);
				}
			});

			// Set inline style for ol counters with "start" attribute for correct appearance
			wysiwyg.querySelectorAll('ol[start]').forEach(ol => {
				ol.style.counterReset = `ol-counter ${+ol.getAttribute('start') - 1}`;
			});
		},
		render() {
			if (!this.$slots.default && !this.text) return;

			const content = this.centered
				? (this.$slots.default
					? <div class={this.b('centered')}>{ this.$slots.default }</div>
					: `<div class="content-block-wysiwyg__centered">${this.text}</div>`
				)
				: (
					this.container
						? (this.$slots.default
							? <div class={this.b('container')}><div class={this.b('container-inner')}>{ this.$slots.default }</div></div>
							: `<div class="base-container content-block-wysiwyg__container"><div class="content-block-wysiwyg__container-inner">${this.text}</div></div>`
						)
						: (this.$slots.default || this.text)
				);

			const modifiers = { withContainer: this.container };

			return this.$slots.default
				? <div class={this.b(null, modifiers)} ref="self">{ content }</div>
				: <div class={this.b(null)} ref="self" domPropsInnerHTML={content}></div>;
		},
	};
